@tailwind base;
@tailwind components;
@tailwind utilities;

.msl-wrp {
  width: 100%;
  outline: none;
  position: relative;
}

.msl {
  overflow: hidden;
  border: 1px solid rgb(203 213 225);
  border-radius: 0px;
  outline: none;
  cursor: text;
  min-height: 38px;
}

.msl-chip,
.msl-single-value {
  font-size: 12px;
  margin: 5px;
}

.msl-chip-delete {
  min-width: 15px;
  min-height: 15px;
  margin-left: 3px;
  background: #dadada;
  color: rgba(0, 0, 0, 0.6);
}

.msl-input {
  display: none;
  outline: none;
  margin: 5px;
  padding: 3px;
  font-size: 14px;
  font-weight: 700;
}

.msl-grp-title,
.msl-options .msl-option {
  font-size: 14px;
  border-radius: 0px;
  padding: 7px 5px;
  cursor: pointer;
  margin: 4px 6px;
  line-break: anywhere;
  line-height: var(--line-height);
  outline: none;
}

.msl-arrow-btn,
.msl-clear-btn {
  width: 13px;
  height: 13px;
}

.msl:not(.msl-active, .msl-active-up):hover {
  border-color: rgb(203 213 225);
}

.msl-clear-btn {
  display: none;
}

.msl-active ~ .msl-options {
  -webkit-clip-path: inset(0 -10px -10px -10px);
  clip-path: inset(0 -10px -10px -10px);
  max-height: var(--menu-max-height);
  transition: border-bottom-left-radius 0.5s ease-in,
    border-bottom-right-radius 0.5s ease-in;
  border: 1px solid rgb(203 213 225);
}

.msl-active-up,
.msl-active ~ .msl-options {
  background: var(--active-menu-background);
  box-shadow: var(--active-menu-shadow);
  z-index: 9999;
  border-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}


textarea {
  resize: none;
}